import React, { useState, useEffect } from "react";
import { firestore } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useParams } from "react-router-dom";
import Ticket from "./utils/Ticket";
import '../styles/TicketPage.css';

const TicketPage = () => {
  const { key } = useParams(); // Obtener la clave desde la URL
  const [ticket, setTicket] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchTicket = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, "keys"));
        const keyDoc = querySnapshot.docs.find((doc) => doc.data().key === key);
        if (keyDoc) {
          const keyData = keyDoc.data();
          setTicket({
            key: keyData.key,
            name: keyData.name,
            date: keyData.date,
            service: keyData.service,
            info: keyData.info,
            state: keyData.state,
          });
          setError("");
        } else {
          setTicket(null);
          setError("Entrada no encontrada.");
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchTicket();
  }, [key]);

  return (
    <div className="ticket-page">
      {error && <p className="error-message">{error}</p>}
      {ticket && (
        <Ticket service={ticket.service} date={ticket.date} version="summary">
          <h1>{ticket.name}</h1>
          <h2>{ticket.service}</h2>
          <h3>{ticket.date} <br /> Playa Los Yuyos, Barranco</h3>
        </Ticket>
      )}
    </div>
  );
};

export default TicketPage;
