import React, { useEffect } from "react";
import { CheckCircle, XCircle, Clipboard } from "lucide-react";
import "../../styles/Toast.css";

const Toast = ({ type, message, title, onClose }) => {
  // Cerrar la notificación automáticamente después de 5 segundos
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 5000);
    return () => clearTimeout(timer);
  }, [onClose]);

  const getIcon = () => {
    switch (type) {
      case "success":
        return <CheckCircle className="success" />;
      case "error":
        return <XCircle className="error" />;
      case "clipboard":
        return <Clipboard className="clipboard" />;
      default:
        return null;
    }
  };

  return (
    <div className={`toast toast-${type}`}>
      <div className="icon">
      {getIcon()}
      </div>
      <div className="toast-content">
        <h4 className="toast-title">{title}</h4>
        <span>{message}</span>
      </div>
      <button className="close-btn" onClick={onClose}>X</button>
    </div>
  );
};

export default Toast;
