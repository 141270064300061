import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Admin from './pages/Admin';
import Account from './pages/Account';
import HomePage from './pages/HomePage'; // Importamos el nuevo componente
import TicketPage from './components/TicketPage';
import Footer from './components/Footer';
import Header from './components/Header';
import ProtectedRoute from './components/ProtectedRoute'; // Importa el componente ProtectedRoute

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <div className="App">
        <Header />
        <main className="Main">
          <Routes>
            <Route path="/mi-cuenta" element={<ProtectedRoute element={<Account />} />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/ticket/:key" element={<TicketPage />} />

            {/* Usamos HomePage en la ruta principal */}
            <Route path="/" element={<HomePage />} />
          </Routes>
          <Footer />
        </main>
      </div>
    </Router>
  );
}

export default App;
