import React, { useState } from "react";
import { firestore } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useNavigate } from 'react-router-dom'; // Importar useNavigate
import '../styles/KeyChecker.css';

const KeyChecker = () => {
  const [key, setKey] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Usar useNavigate para redirigir

  const handleKeyCheck = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, "keys"));
      const keyDoc = querySnapshot.docs.find((doc) => doc.data().key === key);
      if (keyDoc) {
        // Redirigir a la ruta del ticket con la clave
        navigate(`/ticket/${key}`);
        setError("");
      } else {
        setError("Entrada no encontrada.");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="key-checker">
      <label htmlFor="key">
        <h1>
          Ingresa aquí la clave de tu <span className="golden">Experience Ticket</span> y disfruta una nueva forma de vivir.
        </h1>
      </label>
      <input
        name="key"
        id="key"
        type="text"
        value={key}
        onChange={(e) => setKey(e.target.value)}
        placeholder="XXXX-XXXX-XXXX-XXXX"
        className="input"
      />
      <button onClick={handleKeyCheck} className="button">Descubrir experiencia</button>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default KeyChecker;
