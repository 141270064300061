import React, { useRef } from 'react';
import '../../styles/Ticket.css';

const Ticket = ({ children, service, date, version = 'full' }) => {
  const ticketRef = useRef(null);

  const getServiceClass = (service) => {
    switch (service) {
      case "Paddleboard: Sunrise Experience":
        return "sunrise";
      case "Paddleboard: Sunshine Experience":
        return "sunshine";
      case "Paddleboard: Sunset Experience":
        return "sunset";
      default:
        return ""; // Deja vacío si no hay una clase específica
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formatter = new Intl.DateTimeFormat('es-ES', options);
    const parts = formatter.formatToParts(date);

    const month = parts.find(part => part.type === 'month').value;
    const day = parts.find(part => part.type === 'day').value;
    const year = parts.find(part => part.type === 'year').value;

    const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);

    return `${capitalizedMonth} ${day}, ${year}`;
  };

  const formattedChildren = React.Children.map(children, child => {
    if (child.type === 'h3' && date) {
      // Identificamos si el 'child' es un 'h3' y contiene otros elementos además de la fecha
      const childElements = React.Children.toArray(child.props.children);
      const formattedDate = formatDate(date);

      // Reemplazamos solo la fecha en el primer lugar donde aparezca una cadena correspondiente
      const updatedChildren = childElements.map((element, index) => {
        // Reemplazamos el primer string que sea igual a la fecha con la fecha formateada
        if (typeof element === 'string' && element.includes(date)) {
          return formattedDate;
        }
        return element; // Otros elementos no se tocan
      });

      return React.cloneElement(
        child,
        {},
        updatedChildren
      );
    }
    return child;
  });

  return (
    <div className={`ticket ${getServiceClass(service)} ${version === 'detailed' ? 'detailed' : 'summary'}`} ref={ticketRef}>
      <div className="ticket-content">
        {formattedChildren}
      </div>
    </div>
  );
};

export default Ticket;
