import React, { useState } from "react";
import { Clipboard } from "lucide-react";
import Toast from "./Toast";

const ClipboardButton = ({ textToCopy }) => {
  const [toast, setToast] = useState(null); // Estado para gestionar las notificaciones

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setToast({ type: "clipboard", message: `${textToCopy}` });
      })
      .catch((error) => {
        setToast({ type: "error", message: "Error al copiar al portapapeles" });
        console.error("Error al copiar al portapapeles:", error);
      });
  };

  return (
    <div>
      <button className="clipboard-button" onClick={handleCopy}>
        <Clipboard /> {/* Ícono de copiar */}
      </button>
      {/* Mostrar el Toast solo si existe */}
      {toast && (
        <Toast
          type={toast.type}
          title="Clave y nombre copiados"
          message={toast.message}
          onClose={() => setToast(null)} // Ocultar la notificación después de cerrarla
        />
      )}
    </div>
  );
};

export default ClipboardButton;
