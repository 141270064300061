import React, { useState, useEffect, useRef } from "react";
import { MoreHorizontal, Check, X, Loader } from "lucide-react"; 
import ClipboardButton from "./utils/Clipboard"; 
import Ticket from "./utils/Ticket";

const KeyListGroupedByDate = ({ keys, toggleAttendance, handleEditClick }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date()); 
  const monthNavigatorRef = useRef(null); 
  const [loadingKeys, setLoadingKeys] = useState({}); 
  const [attendanceState, setAttendanceState] = useState({}); // Nuevo estado para manejar asistencia temporalmente

  // Agrupar las keys por fecha
  const groupedKeys = keys.reduce((acc, key) => {
    const keyDate = new Date(key.date);
    const monthYear = `${keyDate.getMonth() + 1}-${keyDate.getFullYear()}`; 
    const day = keyDate.toLocaleDateString('es-ES', { day: 'numeric' });
    const dayOfWeek = keyDate.toLocaleDateString('es-ES', { weekday: 'long' });
    const formattedDate = `${dayOfWeek} ${day}, ${keyDate.toLocaleDateString('es-ES', { month: 'long', year: 'numeric' })}`;
    
    if (!acc[monthYear]) {
      acc[monthYear] = {};
    }
    if (!acc[monthYear][formattedDate]) {
      acc[monthYear][formattedDate] = [];
    }
    acc[monthYear][formattedDate].push(key);
    return acc;
  }, {});

  const months = Object.keys(groupedKeys).sort((a, b) => {
    const [monthA, yearA] = a.split("-").map(Number);
    const [monthB, yearB] = b.split("-").map(Number);
    return new Date(yearB, monthB - 1) - new Date(yearA, monthA - 1);
  });

  const filteredGroupedKeys = groupedKeys[currentMonth.getMonth() + 1 + '-' + currentMonth.getFullYear()] || {};
  const sortedDates = Object.keys(filteredGroupedKeys).sort();
  const sortedKeys = sortedDates.map((date) => ({
    date,
    items: filteredGroupedKeys[date].sort((a, b) => a.service.localeCompare(b.service)),
  }));

  const handleMonthChange = (monthIndex) => {
    const [month, year] = months[monthIndex].split('-').map(Number);
    setCurrentMonth(new Date(year, month - 1));
  };

  const handleToggleAttendance = async (keyId, currentAttendance) => {
    setLoadingKeys((prev) => ({ ...prev, [keyId]: true })); // Activar el estado de loading
    setAttendanceState((prev) => ({ ...prev, [keyId]: !currentAttendance })); // Actualizar temporalmente el estado

    try {
      await toggleAttendance(keyId, currentAttendance); // Ejecutar la función para cambiar el estado de asistencia
    } finally {
      setLoadingKeys((prev) => ({ ...prev, [keyId]: false })); // Desactivar el estado de loading
    }
  };

  useEffect(() => {
    const monthIndex = months.findIndex(
      (monthYear) => {
        const [month, year] = monthYear.split('-').map(Number);
        return (
          month === currentMonth.getMonth() + 1 && year === currentMonth.getFullYear()
        );
      }
    );
    if (monthNavigatorRef.current && monthIndex !== -1) {
      const scrollAmount = monthNavigatorRef.current.scrollWidth / months.length * monthIndex;
      monthNavigatorRef.current.scrollTo({ left: scrollAmount, behavior: "smooth" });
    }
  }, [currentMonth, months]);

  return (
    <div>
      {/* Barra de navegación de meses */}
      <div
        ref={monthNavigatorRef}
        className="month-navigator"
        style={{ display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap' }}
        onWheel={(e) => {
          e.preventDefault();
          monthNavigatorRef.current.scrollLeft += e.deltaY;
        }}
      >
        {months.map((monthYear, index) => {
          const [month, year] = monthYear.split("-");
          const monthName = new Date(year, month - 1).toLocaleString("es-ES", { month: "long" }).toUpperCase();
          return (
            <button
              key={monthYear}
              className={`button ${index === months.findIndex(m => m === `${currentMonth.getMonth() + 1}-${currentMonth.getFullYear()}`) ? 'selected' : ''}`}
              onClick={() => handleMonthChange(index)}
              style={{ padding: '10px', cursor: 'pointer' }}
            >
              {`${monthName} ${year}`}
            </button>
          );
        })}
      </div>

      <ul className="key-list">
        <h3>Claves existentes</h3>
        {sortedKeys.map(({ date, items }) => (
          <div key={date} className="date-group">
            <h4>{date}</h4>
            {items.map((key) => (
              <li key={key.id} className={`key-info ${attendanceState[key.id] !== undefined ? (attendanceState[key.id] ? "attending" : "not-attending") : (key.attendance ? "attending" : "not-attending")}`}>
                {/* Renderizar el componente Ticket */}
                <div className="key-options">
                  <button className="more-button" onClick={() => handleEditClick(key)}>
                    <MoreHorizontal />
                  </button>
                  <ClipboardButton textToCopy={`${key.key} • ${key.name}`} />
                  {/* Botón de cambio de asistencia */}
                  <button 
                    className="attendance-button" 
                    onClick={() => handleToggleAttendance(key.id, key.attendance)}
                    disabled={loadingKeys[key.id]} // Desactivar el botón mientras está en loading
                  >
                    {loadingKeys[key.id] ? (
                      <Loader className="spinning" /> // Aro giratorio durante la carga
                    ) : attendanceState[key.id] !== undefined ? (
                      attendanceState[key.id] ? <Check /> : <X />
                    ) : key.attendance ? (
                      <Check />
                    ) : (
                      <X />
                    )}
                  </button>
                </div>
                <Ticket version="detailed" service={key.service} date={key.date}>
                  <p><strong>{key.name}</strong></p>
                  <p>{key.state} • {key.info}</p>
                  <p>{key.key}</p>
                </Ticket>
              </li>
            ))}
          </div>
        ))}
      </ul>
    </div>
  );
};

export default KeyListGroupedByDate;
