import React, { useState, useEffect } from "react";
import { updateDoc, doc } from "firebase/firestore";
import { firestore } from "../firebase";
import KeyForm from "./KeyForm"; // Importar componente reutilizable
import Popup from './utils/Popup'; // Importar el componente Popup

const EditKeyButton = ({ keyData, onClose, fetchKeys }) => {
  const [formData, setFormData] = useState({
    name: "",
    date: "",
    service: "",
    state: "",
    info: "",
    attendance: false,
  });

  const [error, setError] = useState("");

  useEffect(() => {
    if (keyData) {
      setFormData({
        name: keyData.name || "",
        date: keyData.date || "",
        service: keyData.service || "",
        state: keyData.state || "",
        info: keyData.info || "",
        attendance: keyData.attendance || false,
      });
    }
  }, [keyData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAttendanceChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      attendance: e.target.checked,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const keyRef = doc(firestore, "keys", keyData.id);
      await updateDoc(keyRef, formData);
      onClose();
      fetchKeys(); // Refrescar la lista de keys
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Popup isOpen={true} togglePopup={onClose}>
      <h2>Editar Clave</h2>
      <KeyForm
        formData={formData}
        onInputChange={handleInputChange}
        onAttendanceChange={handleAttendanceChange}
        onSubmit={handleSave}
        error={error}
      />
    </Popup>
  );
};

export default EditKeyButton;
