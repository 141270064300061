// src/pages/HomePage.js
import React from 'react';
import KeyChecker from '../components/KeyChecker';
import '../styles/HomePage.css'; // Para agregar cualquier estilo adicional que necesites

const HomePage = () => {
  return (
    <div className="homepage">
      <section className="key-checker-section">
        <KeyChecker />
      </section>
      
      {/* Puedes agregar más secciones para futuros elementos */}
      <section className="about-section">
        <h2>Descubre nuevas experiencias</h2>
        <p>
          Vive momentos únicos a través de nuestras experiencias diseñadas para 
          conectar con la esencia de cada evento.
        </p>
      </section>

      <section className="featured-experiences">
        <h2>Experiencias destacadas</h2>
        <div className="experience-card">
          <h3>Aventura en la playa</h3>
          <p>Descubre la magia de las olas en la costa de Barranco.</p>
        </div>
        <div className="experience-card">
          <h3>Recorrido cultural en Lima</h3>
          <p>Explora la rica historia de la ciudad con nuestras audioguías.</p>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
