import React, { useState, useEffect } from "react";
import { auth, firestore } from "../firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import KeyRegistration from "./KeyRegistration";
import EditKeyPopup from "./EditKeyButton";
import KeyListGroupedByDate from "./KeyListGroupedByDate"; // Importar el nuevo componente
import '../styles/KeyManager.css';

const KeyManager = () => {
  const [keys, setKeys] = useState([]);
  const [error, setError] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);

  // Detectar autenticación del usuario
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const email = user.email || "";
        setIsAuthenticated(true);
        setIsAdmin(email.endsWith("@ooooo.pe")); // Solo emails del dominio permitido
      } else {
        setIsAuthenticated(false);
        setIsAdmin(false);
      }
    });

    fetchKeys();

    return () => unsubscribe();
  }, []);

  // Obtener todas las keys
  const fetchKeys = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, "keys"));
      const fetchedKeys = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setKeys(fetchedKeys);
    } catch (error) {
      setError(error.message);
    }
  };

  const toggleAttendance = async (keyId, currentAttendance) => {
    try {
      const keyRef = doc(firestore, "keys", keyId);
      await updateDoc(keyRef, { attendance: !currentAttendance });
      fetchKeys(); // Refrescar la lista de keys
    } catch (error) {
      setError(error.message);
    }
  };

  const handleEditClick = (key) => {
    setSelectedKey(key);
  };

  const handleClosePopup = () => {
    setSelectedKey(null);
  };

  // Función de cierre de sesión
  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      setError(error.message);
    }
  };

  const getServiceClass = (service) => {
    switch (service) {
      case "Paddleboard: Sunrise Experience":
        return "sunrise";
      case "Paddleboard: Sunshine Experience":
        return "sunshine";
      case "Paddleboard: Sunset Experience":
        return "sunset";
      default:
        return ""; // Deja vacío si no hay una clase específica
    }
  };

  return (
    <div className="key-manager">
      {isAuthenticated && (
        <>
          <button className="button" onClick={handleLogout}>
            Cerrar sesión
          </button>
          {isAdmin && <KeyRegistration fetchKeys={fetchKeys} />}
          <KeyListGroupedByDate
            keys={keys}
            toggleAttendance={toggleAttendance}
            handleEditClick={handleEditClick}
            getServiceClass={getServiceClass}
          />
          {selectedKey && (
            <EditKeyPopup keyData={selectedKey} onClose={handleClosePopup} fetchKeys={fetchKeys} />
          )}
        </>
      )}
    </div>
  );
};

export default KeyManager;